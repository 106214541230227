import React, {lazy, Suspense} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// import AdminSection from "./AdminSection/AdminSection";
const AdminSection= lazy(() => import("./AdminSection/AdminSection"));
const Client = lazy(() => import("./Client/Client"));
const QuizMasterAdmin = lazy(() => import("./QuizMasterAdmin/QuizMasterAdmin"));
const ClientAdmin = lazy(() => import("ClientAdmin/ClientAdmin"));
// const toast = lazy(()=>import ("react-toastify"))

// import Client from "./Client/Client";
// import QuizMasterAdmin from "./QuizMasterAdmin/QuizMasterAdmin";
// import ClientAdmin from "ClientAdmin/ClientAdmin";

function App() {
  let theme = createTheme({});
  theme = createTheme(theme, {
    palette: {
      bgColor: theme.palette.augmentColor({
        color: {
          main: "#F9F9F9",
        },
      }),
      navBg: {
        main: "#005F6A",
      },
      orange: {
        main: "#FFF0C9",
        dark: "#F8B813",
      },
      blue: {
        dark: "#005F6A",
        main: "#005F6A",
        light: "#F0FDFF",
      },
      grey: {
        dark: "#7E7E7E",
        main: "#7E7E7E",
        light: "#E9E9E9",
      },
      green: {
        light: "#E3FFE0",
        main: "#28B536",
        dark: "#28B536",
      },
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route exact path="/admin/*" element={<Suspense fallback={<>Loading...</> }><AdminSection /></Suspense>} />
            <Route exact path="/*" element={
              <Suspense fallback={<>Loading...</> }> <Client /></Suspense>
             } />
            <Route exact path="/quizmaster/*" element={
             <Suspense fallback={<>Loading...</> }>  <QuizMasterAdmin /></Suspense>} />
            <Route exact path="/clientadmin/*" element={ <Suspense fallback={<>Loading...</> }><ClientAdmin /></Suspense>} />
          </Routes>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </>
  );
}

export default App;
